import { PdfInfo, PhotoInfo } from './types/Car';

const maxNumberOfFiles = 30;

// Alle tekstene kan finnes her: https://upload-widget.cloudinary.com/1.25.4/global/text.json

const sharedSettings = {
  sources: ['local', 'url', 'camera'],
  defaultSource: 'local',
  showAdvancedOptions: false,
  cloudName: 'hassel-software-as',
  showPoweredBy: false,
  language: 'no',
  text: {
    no: {
      or: 'eller',
      back: 'Tilbake',
      close: 'Lukk',
      menu: {
        files: 'Mine filer',
        web: 'Internett',
        camera: 'Kamera',
      },
      local: {
        browse: 'Finn filer',
        dd_title_single: 'Dra og slipp en fil her',
        dd_title_multi: 'Dra og slipp bilder her',
      },
      url: {
        main_title: 'Internett-adresse',
        inner_title: 'Offentlig adresse til en fil du vil laste opp:',
        input_placeholder: 'http://annen.fil.eksempel/bilder/annet-bilde.jpg',
      },
      camera: {
        main_title: 'Kamera',
        capture: 'Ta bilde',
        cancel: 'Avbryt',
        take_pic: 'Ta et bilde og last det opp',
        explanation:
          'Pass på at kameraet ditt er tilkoblet og at nettleseren din tillater bruk av kameraet. Når du er klar, klikk på "Ta bilde"',
        camera_error: 'Fikk ikke tilgang til kameraet',
        retry: 'Prøv kamera igjen',
        file_name: 'Kamera_{{time}}',
      },
      crop: {
        title: 'Beskjær',
        crop_btn: 'Beskjær',
        reset_btn: 'Nullstill',
        close_btn: 'Ja',
        image_error: 'Feil ved lasting av bilde',
        corner_tooltip: 'Dra hjørnet for å endre størrelse',
        close_prompt: 'Lukking av vinduet vil avbryte alle opplastinger. Er du sikker?',
        handle_tooltip: 'Dra håndtaket for å endre størrelse',
      },
      uploader: {
        errors: {
          max_number_of_files: `Du forsøkte å laste opp for mange bilder. Maks antall er ${maxNumberOfFiles}.`,
          allowed_formats: 'Ulovlig filformat',
        },
      },
      queue: {
        title: 'Opplastingskø',
        title_uploading_with_counter: 'Laster opp {{num}} bilder',
        title_uploading: 'Laster opp bilder',
        mini_title: 'Opplasted',
        mini_title_uploading: 'Laster opp',
        show_completed: 'Vis ferdige',
        retry_failed: 'Forsøk de som feilet om igjen',
        abort_all: 'Avbryt alle',
        upload_more: 'Last opp flere',
        done: 'Ferdig',
        mini_upload_count: '{{num}} lastet opp',
        mini_failed: '{{num}} feilet',
        statuses: {
          uploading: 'Laster opp...',
          error: 'Feil',
          uploaded: 'Ferdig',
          aborted: 'Avbrutt',
        },
      },
    },
  },
};

export const openMainPhotoPicker = (addPhoto: (info: PhotoInfo) => void) => {
  // @ts-ignore
  cloudinary.openUploadWidget(
    {
      uploadPreset: 'demobil-hovedbilde',
      cropping: true,
      singleUploadAutoClose: false,
      croppingAspectRatio: '1.333333',
      showSkipCropButton: false,
      ...sharedSettings,
    },
    (_error: any, result: any) => {
      if (result.event === 'success') {
        addPhoto({ url: result.info?.secure_url, id: result.info?.public_id });
      }
    }
  );
};

export const openMultiPhotoPicker = (addImage: (info: PhotoInfo) => void) => {
  // @ts-ignore
  cloudinary.openUploadWidget(
    {
      uploadPreset: 'demobil-tilleggsbilder',
      multiple: true,
      maxFiles: maxNumberOfFiles,
      ...sharedSettings,
    },
    (_error: any, result: any) => {
      if (result.event === 'success') {
        addImage({ url: result.info?.secure_url, id: result.info?.public_id });
      }
    }
  );
};

export const openConditionReportPicker = (addPdf: (info: PdfInfo) => void) => {
  // @ts-ignore
  cloudinary.openUploadWidget(
    {
      ...sharedSettings,
      uploadPreset: 'internbil-tilstandsrapport',
      sources: ['local', 'url'],
      singleUploadAutoClose: true,
      defaultSource: 'local',
      clientAllowedFormats: 'pdf',
      multiple: false,
      text: {
        ...sharedSettings.text,
        no: {
          ...sharedSettings.text.no,
          url: {
            ...sharedSettings.text.no.url,
            input_placeholder: 'http://annen.fil.eksempel/tilstandsrapporter/tilstandsrapport.pdf',
          },
        },
      },
    },
    (_error: any, result: any) => {
      if (result.event === 'success') {
        addPdf({ url: result.info?.secure_url, id: result.info?.public_id, pages: result.info?.pages });
      }
    }
  );
};
