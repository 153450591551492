import { CircularProgress } from '@mui/material';
import c from './Loader.module.css';
import classNames from 'classnames';

const Loader = ({ fullscreen }: { fullscreen?: boolean }) => {
  return (
    <div className={classNames(c.container, { [c.fullscreen]: fullscreen })}>
      <CircularProgress size={fullscreen ? 100 : 80} />
    </div>
  );
};

export default Loader;
