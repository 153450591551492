import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import ReactRouterPrompt from 'react-router-prompt';

const Prompt: React.FC<{ hasUnsavedChanges: boolean }> = ({ hasUnsavedChanges }) => {
  return (
    <ReactRouterPrompt when={hasUnsavedChanges}>
      {({ isActive, onConfirm, onCancel }) => (
        <Dialog open={isActive} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Du har ulagrede endringer. Er du sikker på at du ønsker å gå til en annen side?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>Nei</Button>
            <Button onClick={onConfirm} autoFocus>
              Ja
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </ReactRouterPrompt>
  );
};

export default React.memo(Prompt);
