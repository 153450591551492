import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

interface Props {
  carCategory: string;
  setCarCategory: (category: string) => void;
  className?: string;
}

const CarCategoryRadioButtonsGroup: React.FC<Props> = ({ carCategory, setCarCategory, className }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCarCategory((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl component="fieldset" className={className}>
      <FormLabel component="legend">Internbilkategori</FormLabel>
      <RadioGroup aria-label="bil-kategori" name="bil-kategori" value={carCategory} onChange={handleChange}>
        <FormControlLabel value="Demobil" control={<Radio color="primary" />} label="Demobil" />
        <FormControlLabel value="Administrasjonsbil" control={<Radio color="primary" />} label="Administrasjonsbil" />
        <FormControlLabel value="Utleiebil" control={<Radio color="primary" />} label="Utleiebil" />
      </RadioGroup>
    </FormControl>
  );
};
export default CarCategoryRadioButtonsGroup;
