import { atom } from 'jotai';
import { authTokenAtom } from './auth';

export const userRequestIdAtom = atom<number>(0);

export const usersInRolePageAtom = atom<number>(0);

export const getUsersWithRelevantRoleAtom = atom<Promise<UserResponse | undefined>>(async (get) => {
  get(userRequestIdAtom);
  const page = get(usersInRolePageAtom);
  const token = get(authTokenAtom)?.__raw;
  if (!token) return;
  const response = await fetch(`/nf-functions/get-users?page=${page}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return (await response.json()) as UserResponse;
});

export interface UserResponse {
  users: UserInRole[];
  start: number;
  limit: number;
  total: number;
}

export interface UserInRole {
  userId: string;
  email: string;
  name: string;
}
