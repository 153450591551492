import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

interface Props {
  financialCategory: string;
  setFinancialCategory: (category: string) => void;
  className?: string;
}

const FinancialCategorySelection: React.FC<Props> = ({ financialCategory, setFinancialCategory, className }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFinancialCategory((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl component="fieldset" className={className}>
      <FormLabel component="legend">Eieform</FormLabel>
      <RadioGroup aria-label="finans-kategori" name="finans-kategori" value={financialCategory} onChange={handleChange}>
        <FormControlLabel value="Egeneid" control={<Radio color="primary" />} label="Egeneid" />
        <FormControlLabel value="Leaset" control={<Radio color="primary" />} label="Leaset" />
      </RadioGroup>
    </FormControl>
  );
};
export default FinancialCategorySelection;
