export const capitalize = (string?: string): string => {
  if (!!string?.includes(' ')) {
    return string?.split(' ').map(capitalize).join(' ');
  } else {
    return (!!string && string.length >= 1 && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()) || '';
  }
};

export const cleanUpModelName = (model: string, make: string) => {
  const upperCaseMake = make.toUpperCase();
  const upperCaseModel = model.toUpperCase();

  const dedupedUpperCaseModel = upperCaseModel.startsWith(upperCaseMake)
    ? upperCaseModel.replace(upperCaseMake, '').trim()
    : upperCaseModel;

  return (
    modelDictionary[dedupedUpperCaseModel] ||
    shortModelNames.find((shortModelName) => dedupedUpperCaseModel.startsWith(shortModelName)) ||
    capitalize(dedupedUpperCaseModel)
  );
};

const modelDictionary: { [modelName: string]: string } = {
  'MAZDA CX-5': 'CX-5',
  'MAZDA CX-3': 'CX-3',
  'MAZDA MX-5': 'MX-5',
  'MAZDA MX-30': 'MX-30',
  'MAZDA CX-30': 'CX-30',
  MAZDA3: '3',
  MAZDA6: '6',
  MAZDA2: '2',
  IONIQ: 'IONIQ',
  KONA: 'KONA',
  TUCSON: 'TUCSON',
  'SANTA FE': 'SANTA FE',
  'IONIQ 5': 'IONIQ 5',
  NEXO: 'NEXO',
  IONIQ5: 'IONIQ 5',
  'V60 CROSS COUNTRY': 'V60 Cross Country',
  'V90 CROSS COUNTRY': 'V90 Cross Country',
  I3: 'i3',
  XV: 'XV',
  'DS 7 CROSSBACK': '7 CROSSBACK',
  PROCEED: 'ProCeed',
  XCEED: 'XCeed',
  'E-NIRO': 'E-Niro',
  ASX: 'ASX',
  'HR-V': 'HR-V',
  'CR-V': 'CR-V',
  'I-MIEV': 'i-Miev',
  'E-NV200': 'e-NV200',
  '250': '250',
  NV300: 'NV300',
  'X-TRAIL': 'X-Trail',
  SX4: 'SX4',
  'E-TRON': 'e-tron',
  'SX4 S-CROSS': 'SX4 S-Cross',
  'S-MAX': 'S-MAX',
  'MUSTANG MACH-E': 'Mustang Mach-E',
};

const shortModelNames = ['V90', 'V60', 'V70', 'XC90', 'XC60', 'XC40', 'S60', 'S90', 'XC70', 'ID.3', 'ID.4'];

export const cleanUpMakeName = (make: string) => makeDictionary[make.toUpperCase()] || capitalize(make);

const makeDictionary: { [makeName: string]: string } = {
  BMW: 'BMW',
  BYD: 'BYD',
  MG: 'MG',
  DS: 'DS',
  MINI: 'MINI',
  RAM: 'RAM',
  XPENG: 'XPeng',
  'MERCEDES-BENZ': 'Mercedes-Benz',
};
