import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? 'W1g85VJ5FIpd7utBXodwnx2UVKk61JmF';

export const AuthenticationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      domain="demobil.eu.auth0.com"
      clientId={clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}`,
      }}
      onRedirectCallback={(appState: any) => navigate(appState?.returnTo || window.location.pathname)}
    >
      {children}
    </Auth0Provider>
  );
};
