import React from 'react';
import styles from './MultiPhotoPicker.module.css';
import { Button, Typography } from '@mui/material';
import { openMultiPhotoPicker } from './photoPicker';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { PhotoInfo } from './types/Car';
import { AutoImage } from './CloudinaryImageComponents';
import _ from 'lodash';

export const MultiPhotoPicker = ({
  onChange,
  defaultValue,
}: {
  onChange: (e: any) => void;
  defaultValue: PhotoInfo[];
}) => {
  const [extraPhotos, setExtraPhotos] = React.useState<PhotoInfo[]>(defaultValue);

  const addExtraPhotoUrl = (photo: PhotoInfo) => {
    setExtraPhotos((prevState) => [...prevState, photo]);
  };

  const removeExtraImage = (index: number) => {
    setExtraPhotos((prevState) => [...prevState.slice(0, index), ...prevState.slice(index + 1)]);
  };

  React.useEffect(() => {
    if (!_.isEqual(extraPhotos, defaultValue)) {
      onChange(extraPhotos);
    }
  }, [extraPhotos, onChange, defaultValue]);

  return (
    <>
      <Typography variant={'h3'} className={styles.header}>
        Ekstrabilder
      </Typography>
      <div className={styles.extraPhotosContainer}>
        {extraPhotos.map((info: PhotoInfo, index: number) => (
          <div key={info.id} className={styles.extraPhotoContainer}>
            <AutoImage
              publicId={info.id}
              alt="Ekstrabilde av bil"
              className={styles.extraPhoto}
              width={200}
              crop="limit"
            />
            <button
              onClick={() => removeExtraImage(index)}
              className={styles.removeExtraImageButton}
              title="Fjern bilde"
              type="button"
            >
              <DeleteOutlineIcon className={styles.deleteIcon} />
            </button>
          </div>
        ))}
      </div>
      <Button
        onClick={() => openMultiPhotoPicker(addExtraPhotoUrl)}
        variant="outlined"
        color="primary"
        size="medium"
        className={styles.uploadImageButton}
      >
        Legg til bilder
      </Button>
    </>
  );
};
