import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#4a5e84',
      main: '#1d3557',
      dark: '#000e2e',
      contrastText: '#fff',
    },
    secondary: {
      main: '#d5d5d5',
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
      marginTop: '1em',
    },
    h3: {
      fontSize: '1.5rem',
    },
    body1: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '16px',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h3: 'h1',
          h4: 'h2',
          h5: 'h3',
        },
      },
    },
  },
});
