import React from 'react';
import { Bid, BidUser } from './types/Car';
import TableContainer from '@mui/material/TableContainer';
import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { DateTime } from 'luxon';
import c from './BidsTable.module.css';

const currencyNumberFormat = Intl.NumberFormat('no-NO', {
  style: 'currency',
  currency: 'NOK',
  maximumSignificantDigits: 10,
});

const getDisplayNameForUser = ({ name, company, email }: BidUser) => {
  const listOfValues = [];
  if (!!name && name !== email) {
    listOfValues.push(name);
  }

  if (!!company) {
    listOfValues.push(company);
  }

  if (listOfValues.length > 0) {
    return `(${listOfValues.join(', ')})`;
  } else {
    return '';
  }
};

export const BidsTable: React.FC<{ bids: Bid[] }> = ({ bids }) => (
  <TableContainer component={Paper}>
    <Table aria-label="budtabell">
      <TableHead>
        <TableRow>
          <TableCell className={c.cell}>Budgiver</TableCell>
          <TableCell className={c.cell}>Bud</TableCell>
          <TableCell align="right" className={c.cell}>
            Tidspunkt
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {bids.map((bid) => (
          <TableRow key={bid.timestamp}>
            <TableCell component="th" scope="row" className={c.cell}>
              <a href={`mailto:${bid.user.email}`}>{bid.user.email}</a> {getDisplayNameForUser(bid.user)}
            </TableCell>
            <TableCell className={c.cell}>{currencyNumberFormat.format(bid.bid)}</TableCell>
            <TableCell align="right" className={c.cell}>
              {DateTime.fromISO(bid.timestamp).setLocale('no').toFormat('dd. MMM yyyy kl. HH:mm:ss')}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
