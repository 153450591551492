import { DateTime } from 'luxon';
import { OptionsObject } from 'notistack';
import { FormCar } from '../types/FormCar';
import React from 'react';
import { RegistrationInfo } from '../types/kjoretoyopplysninger';

const createCar = async (
  data: FormCar,
  token: string,
  registrationInfo: RegistrationInfo,
  enqueueSnackbar: (message: React.ReactNode, options?: OptionsObject | undefined) => void
) => {
  try {
    const response = await fetch('/nf-functions/create-car', {
      body: JSON.stringify({
        transmission: registrationInfo.girkasse,
        numberOfDoors: registrationInfo.antallDorer,
        numberOfSeats: registrationInfo.antallSitteplasser,
        color: registrationInfo.farge ?? 'Ukjent',
        fuelType: registrationInfo.drivstoff,
        carType: registrationInfo.bilType ?? null,
        engineEffect: registrationInfo.motorEffektHK?.toFixed(0) ?? 'Ukjent',
        title: data.title,
        make: data.make,
        model: data.model,
        price: data.price,
        buyNowPrice: data.buyNowPrice,
        isSold: data.isSold,
        finalPrice: data.finalPrice,
        regNumber: registrationInfo.registrationNumber,
        contractPrice: data.contractPrice,
        pricePaidForOppkjoperCar: data.pricePaidForOppkjoperCar,
        vat: data.vat,
        residualValue: data.residualValue,
        leasingStartDate:
          data.leasingStartDate instanceof DateTime ? data.leasingStartDate?.toISODate() : data.leasingStartDate,
        vinNumber: registrationInfo.vinNumber,
        mileage: data.mileage,
        description: data.description,
        modelYear: data.modelYear,
        deliveryDate: data.deliveryDate instanceof DateTime ? data.deliveryDate?.toISODate() : data.deliveryDate,
        auctionEndDate: data.auctionEndDate instanceof DateTime ? data.auctionEndDate?.toISO() : data.auctionEndDate,
        registrationDate: registrationInfo.forstegangRegDatoNorgeAsDate?.toISODate(),
        mainImage: data.mainImage || {
          id: 'Bruktbil/default-bilde',
          url: 'https://res.cloudinary.com/hassel-software-as/image/upload/v1612793216/Bruktbil/default-bilde.png',
        },
        carCategory: data.carCategory,
        financialCategory: data.financialCategory,
        wheelDrive: registrationInfo.hjuldrift,
        adText: data.adText,
        extraPhotos: data.extraPhotos ?? [],
        conditionReport: data.conditionReport,
        equipmentList: data.equipmentList,
        location: data.location,
        weight: registrationInfo.egenvekt,
        hasBeenNotified: false,
      }),
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      enqueueSnackbar('Bilen ble lagret', { variant: 'success' });
    } else {
      enqueueSnackbar('Noe gikk galt under lagring av bilen. Prøv igjen senere.', { variant: 'warning' });
    }
  } catch (e) {
    enqueueSnackbar('Noe gikk galt under lagring av bilen. Prøv igjen senere.', { variant: 'warning' });
    console.log('API error', e);
  }
};

export const saveCar = async (
  id: string,
  car: FormCar,
  token: string,
  enqueueSnackbar: (message: React.ReactNode, options?: OptionsObject | undefined) => void
) => {
  try {
    const response = await fetch(`/nf-functions/update-car/${id}`, {
      body: JSON.stringify({
        make: car.make,
        model: car.model,
        title: car.title,
        price: car.price,
        buyNowPrice: car.buyNowPrice,
        isSold: car.isSold,
        finalPrice: car.finalPrice,
        contractPrice: car.contractPrice,
        pricePaidForOppkjoperCar: car.pricePaidForOppkjoperCar,
        vat: car.vat,
        residualValue: car.residualValue,
        leasingStartDate:
          car.leasingStartDate instanceof DateTime ? car.leasingStartDate?.toISODate() : car.leasingStartDate,
        mileage: car.mileage,
        description: car.description,
        modelYear: car.modelYear,
        deliveryDate: car.deliveryDate instanceof DateTime ? car.deliveryDate?.toISODate() : car.deliveryDate,
        auctionEndDate: car.auctionEndDate instanceof DateTime ? car.auctionEndDate?.toISO() : car.auctionEndDate,
        mainImage: car.mainImage,
        carCategory: car.carCategory,
        financialCategory: car.financialCategory,
        adText: car.adText,
        extraPhotos: car.extraPhotos,
        conditionReport: car.conditionReport,
        equipmentList: car.equipmentList,
        location: car.location,
      }),
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      enqueueSnackbar('Dine endringer ble lagret', { variant: 'success' });
    } else {
      enqueueSnackbar('Noe galt skjedde under redigering. Prøv igjen senere', { variant: 'warning' });
    }
  } catch (e) {
    console.log('API error', e);
    enqueueSnackbar('Noe galt skjedde under redigering. Prøv igjen senere', { variant: 'warning' });
  }
};

export default createCar;
