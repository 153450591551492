import { DateTime } from 'luxon';
import { PaymentDueTime, pmt, ppmt } from 'financial';
import { getFinancialProperties } from '../dealerships';
import _ from 'lodash';

export interface ListCar {
  refId: string;
  title: string;
  price?: number;
  buyNowPrice?: number;
  isSold?: DateTime | null;
  contractPrice?: number;
  pricePaidForOppkjoperCar?: number;
  vat?: number;
  residualValue?: number;
  leasingStartDate?: DateTime | string;
  vinNumber: string;
  mileage?: number;
  regNumber: string;
  deliveryDate?: DateTime | string;
  auctionEndDate?: DateTime | string;
  registrationDate?: string;
  mainImage?: PhotoInfo;
  carCategory?: string;
  carType?: string;
  fuelType: string;
  weight?: number;
  financialCategory?: string;
  location: string;
  highestBid: Bid;
  numberOfBids: number;
  finalPrice?: number;
}

export interface Car {
  title: string;
  make: string;
  model: string;
  transmission: string;
  price?: number;
  buyNowPrice?: number;
  isSold?: DateTime | null;
  contractPrice?: number;
  pricePaidForOppkjoperCar?: number;
  vat?: number;
  residualValue?: number;
  leasingStartDate?: DateTime | string;
  vinNumber: string;
  mileage?: number;
  regNumber: string;
  description?: string;
  modelYear: number;
  color: string;
  fuelType: string;
  deliveryDate?: DateTime | string;
  auctionEndDate?: DateTime | string;
  registrationDate?: string;
  mainImage?: PhotoInfo;
  carCategory?: string;
  financialCategory?: string;
  wheelDrive: string;
  adText: string;
  extraPhotos: PhotoInfo[];
  conditionReport?: PdfInfo;
  equipmentList: string[];
  numberOfDoors: string;
  numberOfSeats: string;
  carType: string | null;
  engineEffect: string;
  location: string;
  weight?: number;
  bids?: Bid[];
  finalPrice?: number;
}

export interface PhotoInfo {
  url: string;
  id: string;
}

export interface Bid {
  user: BidUser;
  bid: number;
  timestamp: string;
}

export interface BidUser {
  email: string;
  orgNr?: string;
  name?: string;
  company?: string;
}

export interface PdfInfo extends PhotoInfo {
  pages: number;
}

export const getRegistrationDate = (car: ListCar | Car) => {
  if (!!car.registrationDate) {
    return DateTime.fromFormat(car.registrationDate, 'yyyy-MM-dd');
  } else {
    if (!!car.deliveryDate) {
      return getDefaultRegistrationDateFromDeliveryDate(car.deliveryDate);
    } else {
      return DateTime.local();
    }
  }
};

const getDefaultRegistrationDateFromDeliveryDate = (deliveryDate: DateTime | string) => {
  const dateTime =
    deliveryDate instanceof DateTime ? deliveryDate : DateTime.fromFormat(deliveryDate as string, 'yyyy-MM-dd');
  return dateTime.minus({ months: 4 });
};

export const getAuctionEndDate = (car: ListCar | Car) =>
  !car.auctionEndDate
    ? DateTime.local()
    : car.auctionEndDate instanceof DateTime
    ? car.auctionEndDate
    : DateTime.fromISO(car.auctionEndDate);

export const getDateTime = (date?: string | DateTime): DateTime | undefined =>
  !date ? undefined : date instanceof DateTime ? date : DateTime.fromISO(date);

export const getAccountingInfoToday = (car: ListCar | Car, currentContractPrice: number, price: number) =>
  getAccountingInfoForDate(car, currentContractPrice, price, DateTime.local());

export const getAccountingInfoForWhenCarIsSold = (
  car: ListCar | Car,
  currentContractPrice: number,
  price: number,
  deliveryDate: DateTime
) => getAccountingInfoForDate(car, currentContractPrice, price, DateTime.max(deliveryDate, DateTime.local()));

const getAccountingInfoForDate = (
  car: ListCar | Car,
  currentContractPrice: number,
  price: number,
  dateForCalculation: DateTime
) => {
  const registrationDate = getRegistrationDate(car);
  const diffInMonths = dateForCalculation.diff(registrationDate, 'months');
  const numberToDeductPerMonth = currentContractPrice * 0.01;

  const accountingValue = currentContractPrice - Math.floor(diffInMonths.months) * numberToDeductPerMonth;

  return {
    accountingValue,
    salesMargin: price - (accountingValue + getRegistrationCost(car)),
  };
};

export const getLeasingAccountingInfo = (
  car: ListCar | Car,
  price: number,
  currentContractPrice: number,
  vat: number,
  residualValue: number,
  deliveryDate: DateTime,
  leasingStartDate: DateTime,
  carType: 'Personbil' | 'Varebil'
) => {
  const { initialFee, monthlyFee, durationInMonths, depositAsFraction, interestAsFraction, buyOutFee } =
    getFinancialProperties().leasing;

  const kontraktspris = currentContractPrice;
  const startleieProsent = depositAsFraction;
  const etableringsGebyr = initialFee;
  const manedligRente = interestAsFraction / 12;

  const startleie = (kontraktspris - vat) * startleieProsent;

  const valueAtStartOfLeasing = kontraktspris + etableringsGebyr - vat - startleie;

  const actualLeasingStartDate = leasingStartDate.plus({ months: 1 }).startOf('month');
  const leasingEndDate = DateTime.max(DateTime.local(), deliveryDate);

  const numberOfMonthsCarHasBeenLeased = Math.ceil(leasingEndDate.diff(actualLeasingStartDate, 'months').months);

  const manedsbelop = pmt(manedligRente, durationInMonths, -valueAtStartOfLeasing, residualValue, PaymentDueTime.Begin);

  // Vi må starte utregningen en måned "senere", siden vi beregner renter i starten av måneden og ipmt-formelen da sier at renta er 0 siden lånet ikke har hatt noe varighet enda.
  // Slik operer tydeligvis ikke Nordea, så da "hopper" vi over en periode for å matche med deres beregninger
  const paymentsMadeOnCar = _.chain(_.range(2, numberOfMonthsCarHasBeenLeased + 2))
    .map((per) =>
      ppmt(manedligRente, per, durationInMonths, -valueAtStartOfLeasing, residualValue, PaymentDueTime.Begin)
    )
    .map((x) => Math.round(x))
    .sum()
    .value();

  const presentValueOfCar = valueAtStartOfLeasing - paymentsMadeOnCar + buyOutFee;

  let vatToPayback = 0;

  if (numberOfMonthsCarHasBeenLeased <= 12) {
    const monthsWithDoubleRate = 12 - numberOfMonthsCarHasBeenLeased;
    vatToPayback += monthsWithDoubleRate * (vat / 30.0);
    vatToPayback += vat * (36 / 60.0);
  } else {
    const monthsWithSingleRate = 36 - (numberOfMonthsCarHasBeenLeased - 12);
    vatToPayback += monthsWithSingleRate * (vat / 60.0);
  }

  if (carType === 'Varebil') {
    vatToPayback = 0;
  }

  const totalPriceToBuyOutCar = presentValueOfCar + vatToPayback + getRegistrationCost(car);
  return {
    valueAtStartOfLeasing,
    presentValueOfCar,
    monthlyPrice: manedsbelop + monthlyFee,
    vatToPayback,
    totalPriceToBuyOutCar,
    salesMarginLeasing: price - totalPriceToBuyOutCar,
    numberOfMonthsCarHasBeenLeased,
    leasingEndDate,
  };
};

export const getRegistrationCost = (car: ListCar | Car) => {
  if (car.fuelType === 'Elektrisitet' || car.fuelType === 'Hydrogen') {
    return 0;
  } else if (car.weight && car.weight <= 1200) {
    return 4322;
  } else {
    return 6595;
  }
};

const toDateTime = (stringOrDateTime: string | DateTime) =>
  stringOrDateTime instanceof DateTime ? stringOrDateTime : DateTime.fromFormat(stringOrDateTime, 'yyyy-MM-dd');

export const getAccountingInfoForCarToday = (car: ListCar) =>
  getAccountingInfoToday(car, car.contractPrice ?? 0, car.price ?? 0);

export const getAccountingInfoForOppkjoperCar = (car: ListCar) => ({
  accountingValue: car.pricePaidForOppkjoperCar ?? 0,
  salesMargin: (car.price ?? 0) - (car.pricePaidForOppkjoperCar ?? 0),
});

export const getAccountingInfoForCarWhenItIsSold = (car: ListCar) =>
  getAccountingInfoForWhenCarIsSold(
    car,
    car.contractPrice ?? 0,
    car.price ?? 0,
    toDateTime(!!car.deliveryDate ? car.deliveryDate : car.auctionEndDate!)
  );

export const getLeasingAccountingInfoForCarToday = (car: ListCar) =>
  getLeasingAccountingInfo(
    car,
    car.price ?? 0,
    car.contractPrice ?? 0,
    car.vat ?? 0,
    car.residualValue ?? 0,
    DateTime.local(),
    toDateTime(car.leasingStartDate ?? toDateTime(car.registrationDate ?? DateTime.local())),
    car?.carType === 'Varebil' ? 'Varebil' : 'Personbil'
  );

export const getLeasingAccountingInfoForCarWhenItIsSold = (car: ListCar) =>
  getLeasingAccountingInfo(
    car,
    car.price ?? 0,
    car.contractPrice ?? 0,
    car.vat ?? 0,
    car.residualValue ?? 0,
    toDateTime(!!car.deliveryDate ? car.deliveryDate : car.auctionEndDate!),
    toDateTime(car.leasingStartDate ?? toDateTime(car.registrationDate ?? DateTime.local())),
    car?.carType === 'Varebil' ? 'Varebil' : 'Personbil'
  );

export const getCarAgeInDays = (car: ListCar) => {
  const registrationDate = getRegistrationDate(car);
  return Math.abs(Math.floor(registrationDate.diffNow('days').days));
};
