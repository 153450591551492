import { getUsersWithRelevantRoleAtom, usersInRolePageAtom } from '../atoms/users';
import c from './ManageUsersPage.module.css';
import { Button, Paper } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import React from 'react';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { useAtom, useAtomValue } from 'jotai';

export const ExistingUsersOverview = () => {
  const userResponse = useAtomValue(getUsersWithRelevantRoleAtom);
  const [page, setPage] = useAtom(usersInRolePageAtom);

  if (!userResponse || !userResponse.users) {
    return <div>Fant ingen brukere. Prøv igjen senere.</div>;
  }

  const numberOfPages = Math.ceil(userResponse.total / userResponse.limit);
  return (
    <>
      <div className={c.headerAndPaginationContainer}>
        <h2>Brukere som har tilgang til deres auksjonsløsning (totalt {userResponse.total})</h2>

        <div className={c.paginationContainer}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            type="button"
            startIcon={<ArrowLeft />}
            disabled={page === 0}
            onClick={() => setPage((prevPage) => prevPage - 1)}
          >
            Forrige side
          </Button>
          <div>
            Side {page + 1} av {numberOfPages}
          </div>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            type="button"
            startIcon={<ArrowRight />}
            disabled={page + 1 === numberOfPages}
            onClick={() => setPage((prevPage) => prevPage + 1)}
          >
            Neste side
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="Brukerliste">
          <TableHead>
            <TableRow>
              <TableCell className={`${c.cell} ${c.tableHead}`}>E-postadresse</TableCell>
              <TableCell className={`${c.cell} ${c.tableHead}`}>Navn</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userResponse.users.map((user) => (
              <TableRow key={user.userId}>
                <TableCell component="th" scope="row" className={c.cell}>
                  {user.email}
                </TableCell>
                <TableCell className={c.cell}>{user.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
