interface Dealership {
  roleName: string;
  locations: string[];
  dbCollection: string;
  isOppkjoper?: boolean;
}

const dealerships: Dealership[] = [
  {
    roleName: 'centralgaragenAdmin',
    locations: ['Drammen'],
    dbCollection: 'centralgaragen',
  },
  {
    roleName: 'testAdmin',
    locations: ['Oslo', 'Gursken'],
    dbCollection: 'testing',
  },
  {
    roleName: 'testOppkjoperAdmin',
    locations: ['Oslo', 'Gursken'],
    dbCollection: 'testing',
    isOppkjoper: true,
  },
  {
    roleName: 'demoAdmin',
    locations: ['Drammen'],
    dbCollection: 'demo',
  },
  {
    roleName: 'bilserviceAdmin',
    locations: ['Larvik', 'Sandefjord', 'Tønsberg', 'Holmestrand', 'Kongsberg'],
    dbCollection: 'bilservice',
  },
  {
    roleName: 'bilserviceLarvik',
    locations: ['Larvik'],
    dbCollection: 'bilservice',
  },
  {
    roleName: 'bilserviceSandefjord',
    locations: ['Sandefjord'],
    dbCollection: 'bilservice',
  },
  {
    roleName: 'bilserviceTonsberg',
    locations: ['Tønsberg'],
    dbCollection: 'bilservice',
  },
  {
    roleName: 'bilserviceHolmestrand',
    locations: ['Holmestrand'],
    dbCollection: 'bilservice',
  },
  {
    roleName: 'bilserviceKongsberg',
    locations: ['Kongsberg'],
    dbCollection: 'bilservice',
  },
  {
    roleName: 'frydenboAdmin',
    locations: ['Minde', 'Åsane', 'Førde', 'Sogndal', 'Bergen Vest', 'Sandane', 'Mandal', 'Kristiansand'],
    dbCollection: 'frydenbo',
  },
  {
    roleName: 'frydenboOppkjoperAdmin',
    locations: [
      'Minde',
      'Åsane',
      'Førde',
      'Sogndal',
      'Bergen Vest',
      'Sandane',
      'Mandal',
      'Kristiansand',
      'MyCar Bergen',
    ],
    dbCollection: 'frydenbo',
    isOppkjoper: true,
  },
  {
    roleName: 'frydenboMinde',
    locations: ['Minde'],
    dbCollection: 'frydenbo',
  },
  {
    roleName: 'frydenboÅsane',
    locations: ['Åsane'],
    dbCollection: 'frydenbo',
  },
  {
    roleName: 'frydenboFørde',
    locations: ['Førde'],
    dbCollection: 'frydenbo',
  },
  {
    roleName: 'frydenboSogndal',
    locations: ['Sogndal'],
    dbCollection: 'frydenbo',
  },
  {
    roleName: 'frydenboBergenVest',
    locations: ['Bergen Vest'],
    dbCollection: 'frydenbo',
  },
  {
    roleName: 'frydenboSandane',
    locations: ['Sandane'],
    dbCollection: 'frydenbo',
  },
  {
    roleName: 'frydenboMandal',
    locations: ['Mandal'],
    dbCollection: 'frydenbo',
  },
  {
    roleName: 'frydenboKristiansand',
    locations: ['Kristiansand'],
    dbCollection: 'frydenbo',
  },
  {
    roleName: 'okkenhaugAdmin',
    locations: ['Trondheim', 'Orkanger'],
    dbCollection: 'okkenhaug',
  },
  {
    roleName: 'okkenhaugTrondheim',
    locations: ['Trondheim'],
    dbCollection: 'okkenhaug',
  },
  {
    roleName: 'okkenhaugOrkanger',
    locations: ['Orkanger'],
    dbCollection: 'okkenhaug',
  },
  {
    roleName: 'hyundaiAuksjonAdmin',
    locations: ['Oslo'],
    dbCollection: 'hyundai',
    isOppkjoper: true,
  },
  {
    roleName: 'autotalAuksjonAdmin',
    locations: ['Oppdal'],
    dbCollection: 'autotal',
    isOppkjoper: true,
  },
];

export const getLocations = (role: string) => dealerships.find((x) => x.roleName === role)?.locations ?? [];

export const getDbCollection = (role: string) => dealerships.find((x) => x.roleName === role)?.dbCollection;

export const isOppkjoperRole = (role: string) => dealerships.find((x) => x.roleName === role)?.isOppkjoper ?? false;

export const getAuksjonsHostnameForDbCollection = (dbCollection: string) => {
  switch (dbCollection) {
    case 'frydenbo':
      return 'frydenbo-oppkjoper.bruktbil.shop';
    case 'hyundai':
      return 'hyundai.bruktbil.shop';
    case 'autotal':
      return 'autotal-auksjon.bruktbil.shop';
    default:
      return 'oppkjoper.bruktbil.shop';
  }
};

export const getFinancialProperties = () => {
  return {
    leasing: {
      initialFee: 0,
      monthlyFee: 0,
      durationInMonths: 48,
      depositAsFraction: 0,
      interestAsFraction: 0.0216,
      buyOutFee: 100,
    },
  };
};
