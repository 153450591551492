import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { getSavedCarAtomFamily, getSavedCarsAtom } from '../atoms/savedCars';
import { FormCar } from '../types/FormCar';
import { EditCarForm } from './EditCarForm';
import Loader from '../Loader';
import { saveCar } from './createCar';
import Prompt from '../Prompt';
import { useAtom, useSetAtom } from 'jotai';

const EditCarPage = () => {
  const params = useParams<{ id: string }>();
  const vinFromUrl = params.id;
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { getIdTokenClaims } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();

  const [car, refreshCar] = useAtom(getSavedCarAtomFamily(vinFromUrl ?? ''));

  const navigate = useNavigate();
  const refreshCars = useSetAtom(getSavedCarsAtom);

  const { register, handleSubmit, control, watch, setValue, getValues, formState } = useForm<FormCar>();

  const { errors } = formState;

  useEffect(() => {
    if (formState.isDirty && !hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }

    if (formState.isSubmitSuccessful) {
      setHasUnsavedChanges(false);
      setTimeout(() => {
        refreshCar();
        refreshCars();
        navigate('/');
      }, 0);
    }
  }, [formState, hasUnsavedChanges, setHasUnsavedChanges, refreshCar, refreshCars, navigate]);

  if (!car) return <Loader />;

  const onSubmit = async (data: FormCar) => {
    const token = await getIdTokenClaims();
    data.title = getValues().title;
    await saveCar(car.ref['@ref'].id, data, token?.__raw || '', enqueueSnackbar);
  };

  const [model, make] = watch(['model', 'make']);

  if (make && model) {
    const title = `${make} ${model}`;
    setValue('title', title);
  }

  return (
    <>
      <Prompt hasUnsavedChanges={hasUnsavedChanges} />
      <EditCarForm
        register={register}
        carData={car.data}
        control={control}
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
        watch={watch}
        setValue={setValue}
        isDirty={formState.isDirty}
      />
    </>
  );
};

export default EditCarPage;
