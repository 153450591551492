import { atom } from 'jotai';
import { IdToken } from '@auth0/auth0-react';
import { getLocations, isOppkjoperRole } from '../dealerships';
import { getRole } from '../authUtils';

export const authTokenAtom = atom<IdToken | undefined>(undefined);

const role = atom<string>((get) => {
  const token = get(authTokenAtom);
  if (!token) return '';
  return getRole(token);
});

export const dealershipLocationsAtom = atom<string[]>((get) => getLocations(get(role)));

export const userHasOppkjoperRoleAtom = atom<boolean>((get) => isOppkjoperRole(get(role)));

export const auctionHostnameAtom = atom<string | null>((get) => {
  switch (get(role)) {
    case 'frydenboOppkjoperAdmin':
      return 'frydenbo-oppkjoper.bruktbil.shop';
    case 'hyundaiAuksjonAdmin':
      return 'hyundai.bruktbil.shop';
    case 'autotalAuksjonAdmin':
      return 'autotal-auksjon.bruktbil.shop';
    case 'testOppkjoperAdmin':
      return 'oppkjoper.bruktbil.shop';
    default:
      return null;
  }
});
