import { CloudinaryComponentProps, Image } from 'cloudinary-react';
import * as React from 'react';

export const AutoImage = (props: CloudinaryComponentProps) => {
  const { children, ...rest } = props;
  return (
    <Image fetchFormat="auto" quality="auto" {...rest}>
      {props.children}
    </Image>
  );
};
